export type TPGetPrimeParameter = Parameters<Parameters<typeof TPDirect.card.getPrime>[0]>[0]
export type TPLinePayGetPrimeParameter = Parameters<Parameters<typeof TPDirect.linePay.getPrime>[0]>[0]
export type TPCardUpdateCallback = Parameters<typeof TPDirect.card.onUpdate>[0]
export type TPCardUpdateParameter = Parameters<Parameters<typeof TPDirect.card.onUpdate>[0]>[0]

export enum TPCardFunding {
  UNKNOWN = -1,
  /** 信用卡 (Credit Card) */
  CREDIT,
  /** 簽帳卡 (Debit Card) */
  DEBIT,
  /** 預付卡 (Prepaid Card) */
  PREPAID,
}

export const TapPaySDK = {
  init(fields: any) {
    TPDirect.card.setup({
      // @ts-ignore
      fields,
      styles: {
        // Style all elements
        input: {
          color: '#252729',
        },
        // Styling ccv field
        'input.ccv': {
          'font-size': '16px',
        },
        // Styling expiration-date field
        'input.expiration-date': {
          'font-size': '16px',
        },
        // Styling card-number field
        'input.card-number': {
          'font-size': '16px',
        },
        // style focus state
        ':focus': {
          // 'color': 'black'
        },
        // style valid state
        '.valid': {
          color: '#252729',
        },
        // style invalid state
        '.invalid': {
          color: '#C03F45',
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        '@media screen and (max-width: 400px)': {
          input: {
            color: '#252729',
          },
        },
      },
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11,
      },
    })
  },
  getPrime() {
    return new Promise<TPGetPrimeParameter>((resolve, reject) => {
      if (!TPDirect.card.getTappayFieldsStatus().canGetPrime) {
        reject(new Error('can not get prime'))
      }
      TPDirect.card.getPrime(result => {
        if (result.status !== 0) {
          reject(result)
        }
        resolve(result)
      })
    })
  },
  getLinePayPrime() {
    return new Promise<TPLinePayGetPrimeParameter>((resolve, reject) => {
      TPDirect.linePay.getPrime(result => {
        if (result.status !== 0) {
          reject(result)
        }
        resolve(result)
      })
    })
  },
  onCardUpdate(cb: TPCardUpdateCallback) {
    TPDirect.card.onUpdate(cb)
  },
}
