import { defineStore } from 'pinia'
import { format, parseISO } from 'date-fns'
import { useRegionStore } from './region'
import { getBookingPayment } from '@/service/payment'
import type {
  FpaymentCreditCardInfo,
  NicedayOrderCreateOrderReq,
  PaymentPaymentAmountData,
  FpaymentPaymentReqV2,
  FpromoListByProductData,
} from '@/types/api'
import { EdbPaymentMethodProviderType as ApiEdbPaymentMethodProviderType, EdbCurrencyID as ApiEdbCurrencyID } from '@/types/api'
import { PaymentMethodsType } from '@/types/payment'
import type { PaymentMethodData } from '@/types/payment'
import { CurrencyEnum } from '@/types/filters'
import type { UiProductInfo, UiProductSelectedData } from '@/types/product'
import type { ProductUpgradeResource, ProductAdditionsResource, PaymentProviderInfo } from '@/types/booking'
import { getProductPromos } from '@/service/product'

export const useBookingStore = defineStore('booking', () => {
  const product = ref<Nullable<UiProductInfo>>(null)
  const bookingItem = ref<UiProductSelectedData[]>([])
  const additions = ref<ProductAdditionsResource[]>([])
  const upgrades = ref<ProductUpgradeResource[]>([])
  const bookingItemList = computed(() =>
    upgrades.value.concat(additions.value as any).map(item => ({ ...item, quantity: item.quantity ?? item.count }))
  )

  const hasTax = ref(false)

  const regionStore = useRegionStore()

  const promoList = ref<FpromoListByProductData[]>([])
  const selectedPromoInfo = computed(() => promoList.value.find(promo => promo.code_id === selectedPromoCodeId.value))
  const selectedPromoCodeId = ref(0)
  const isUsingFunCoin = ref(false)
  const paymentInfo = ref<PaymentPaymentAmountData>({})
  const orderId = ref(0)
  const checkDuplicateOrder = ref(true)

  const orderNote = ref('')
  const bookingEmail = ref('')

  const bookingStartTime = computed(() => bookingItem.value[0]?.dateTime || '')
  const bookingEndTime = computed(() => {
    if (!bookingStartTime.value || !product.value?.is_crossday) return ''
    return format(parseISO(bookingItem.value?.at(-1)?.dateTime || ''), 'yyyy-MM-dd')
  })

  const bookingAmount = ref(0)
  const bookingTotalAmount = computed(
    () => upgrades.value.reduce((acc, cur) => acc + cur.amount, 0) + additions.value.reduce((acc, cur) => acc + (cur.amount || 0), 0)
  )
  const bookingTotalOriginAmount = computed(
    () => upgrades.value.reduce((acc, cur) => acc + cur.originAmount, 0) + additions.value.reduce((acc, cur) => acc + cur.originAmount, 0)
  )

  const remarks = ref<Record<string, unknown>>({})
  const remarksCreationParams = computed(() =>
    Object.entries(remarks.value).map(([key, value]) => {
      return {
        remark_id: +key,
        answers: Array.isArray(value)
          ? value.map(v => (typeof v === 'object' ? { sub_id: +v.id, content: `${v.value}` } : { sub_id: +v }))
          : [{ content: `${value}` }],
      }
    })
  )

  const selectedCard = ref<FpaymentCreditCardInfo>({})
  const isCardReady = ref(false)
  /** provider(payment SDK) */
  const paymentProviderInfo = ref<PaymentProviderInfo>({ bincode: '', bankId: '', prime: '' })
  const isNeedCvv = computed(() => selectedCard.value.currency?.id === ApiEdbCurrencyID.CurrencyIDMYR)
  const isGatewayReady = computed(() => {
    switch (selectedPaymentGateway.value.type) {
      case PaymentMethodsType.SAVED_CREDIT_CARD:
        return !!selectedCard.value?.id && (!isNeedCvv.value || !!paymentProviderInfo.value.cvv)
      case PaymentMethodsType.NEW_CREDIT_CARD:
      case PaymentMethodsType.THREE_INSTALLMENTS:
        return isCardReady.value
      case PaymentMethodsType.LINE_PAY:
        return true
      default:
        return false
    }
  })

  const hasBookingData = computed(() => bookingStartTime.value && bookingTotalAmount.value)

  const taxData = reactive({
    taxId: '',
    companyName: '',
    receiptCompany: '',
  })

  const selectedPaymentGateway = ref<PaymentMethodData>({})

  const paymentCurrencyCode = computed<string>(() => {
    const defaultCurrencyCode = selectedPaymentGateway.value?.currency?.code || paymentInfo.value.currency_code || 'TWD'

    if (selectedPaymentGateway.value?.type === PaymentMethodsType.SAVED_CREDIT_CARD) {
      return selectedCard.value.currency?.code || defaultCurrencyCode
    }
    return defaultCurrencyCode
  })

  const paymentTxnAmount = computed(() => Number(paymentInfo.value.multi_currency_info?.txn_amount?.[paymentCurrencyCode.value] || 0))

  const orderCreateParams = computed<NicedayOrderCreateOrderReq>(() => {
    const params: NicedayOrderCreateOrderReq = {
      upgrades: upgrades.value.filter(items => items.upgrade_id >= 0),
      additions: additions.value,
      remarks: remarksCreationParams.value as any,
      bookingtime: bookingStartTime.value,
      checkoutday: bookingEndTime.value,
      email: bookingEmail.value,
      member_note: orderNote.value,
      sale_region_id: regionStore.region?.region_id || 0,
      check_duplicate: checkDuplicateOrder.value,
      referer: '',
      product: {
        pid: product.value?.pid || 0,
        count: upgrades.value.reduce((total, item) => total + item.count, 0) || 1,
      },
    }
    if (hasTax.value && Number(paymentInfo.value.txn_amount) > 0) {
      params.tax_info = {
        tax_id: taxData.taxId,
        company_name: taxData.companyName,
        receipt_company: taxData.receiptCompany,
      }
    }
    return params
  })

  const paymentCreationParams = computed<Partial<FpaymentPaymentReqV2>>(() => {
    const isNewCard =
      selectedPaymentGateway.value.type === PaymentMethodsType.NEW_CREDIT_CARD ||
      selectedPaymentGateway.value.type === PaymentMethodsType.THREE_INSTALLMENTS
    const currencyId = CurrencyEnum[paymentCurrencyCode.value as keyof typeof CurrencyEnum]
    const requiredParams = {
      amount: paymentTxnAmount.value,
      prime: paymentProviderInfo.value.prime,
      from: 'NiceDay',
      promo: selectedPromoInfo.value?.id || undefined,
      code_id: selectedPromoInfo.value?.code_id || undefined,
      promo_usage: selectedPromoCodeId.value ? 1 : 0,
      bonus: Number(paymentInfo.value.used_fun_coin) || 0,
      currency_id: currencyId,
    }

    switch (selectedPaymentGateway.value.type) {
      case PaymentMethodsType.SAVED_CREDIT_CARD:
      case PaymentMethodsType.NEW_CREDIT_CARD:
      case PaymentMethodsType.THREE_INSTALLMENTS:
        return {
          ...requiredParams,
          bin: paymentProviderInfo.value.bincode,
          is_new_card: isNewCard,
          cvv: paymentProviderInfo.value.cvv,
          cardid: isNewCard ? undefined : selectedCard.value?.id,
          installment: selectedPaymentGateway.value.type === PaymentMethodsType.THREE_INSTALLMENTS ? 3 : undefined,
        }
      case PaymentMethodsType.LINE_PAY:
      default:
        return requiredParams
    }
  })

  async function updatePaymentInfo(params: Parameters<typeof getBookingPayment>[0]) {
    paymentInfo.value = await getBookingPayment(params)
  }

  async function updatePromoList() {
    const pid = product.value?.pid || ''
    const { data } = await getProductPromos(pid, {
      booking_time: bookingStartTime.value,
      amount: bookingTotalOriginAmount.value,
      sale_region_id: regionStore.currentRegionId,
    })
    promoList.value = data
  }

  async function initialPayment() {
    const isNeedCardInfo =
      selectedPaymentGateway.value.type === PaymentMethodsType.NEW_CREDIT_CARD ||
      selectedPaymentGateway.value.type === PaymentMethodsType.THREE_INSTALLMENTS
    if (isNeedCardInfo && selectedPaymentGateway.value.provider_type === ApiEdbPaymentMethodProviderType.PaymentMethodProviderTypeTapPay) {
      const primeResult = await TapPaySDK.getPrime()
      const { bincode, bank_id: bankId, prime } = primeResult.card
      paymentProviderInfo.value = { bincode, bankId, prime }
    }
    if (selectedPaymentGateway.value.type === PaymentMethodsType.LINE_PAY) {
      const primeResult = await TapPaySDK.getLinePayPrime()
      const { prime } = primeResult
      paymentProviderInfo.value = { prime }
    }
  }

  return {
    // product context
    product,
    bookingItem,
    additions,
    upgrades,
    // booking context
    bookingAmount,
    bookingTotalAmount,
    bookingTotalOriginAmount,
    bookingEmail,
    bookingEndTime,
    bookingStartTime,
    bookingItemList,
    checkDuplicateOrder,
    selectedCard,
    isCardReady,
    isNeedCvv,
    isGatewayReady,
    paymentProviderInfo,
    hasBookingData,
    promoList,
    selectedPromoInfo,
    selectedPromoCodeId,
    isUsingFunCoin,
    paymentCurrencyCode,
    paymentTxnAmount,
    orderCreateParams,
    orderNote,
    paymentCreationParams,
    paymentInfo,
    selectedPaymentGateway,
    taxData,
    initialPayment,
    updatePaymentInfo,
    updatePromoList,
    orderId,
    remarks,
    hasTax,
  }
})
